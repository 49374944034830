import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Component } from 'react';
import { observer } from 'mobx-react';

import './ModalHelper.scss';

interface ModalHelperProps {
  isOpen: boolean;
  className: string;
  onCloseModal: () => void;
}

@observer
export class ModalHelper extends Component<ModalHelperProps> {

  static defaultProps: {
    className: '',
  };

  onClickModalBackground = () => {
    const { props } = this;
    const { onCloseModal } = props;

    onCloseModal();
  }

  onClickModal = (event: React.SyntheticEvent) => {
    event.stopPropagation();
  }

  render() {
    const { props } = this;
    const { children, className, isOpen } = props;

    return isOpen && ReactDOM.createPortal(
      <div className="modal-helper">
        <div
          className={`modal-content ${className}`}
          onClick={this.onClickModal}
        >
          { children }
        </div>
      </div>,
      document.getElementById('modal'),
    );
  }

}
