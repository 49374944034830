import * as React from "react";
import { Component } from "react";
import { action, computed, observable } from "mobx";
import { observer, inject } from "mobx-react";

import { Button } from "../../button/Button";
import { Form } from "../../form/Form";
import { Input } from "../../input/Input";
import { LoadingOverlay } from "../../loading/LoadingOverlay";
import { MessageType } from "../../form/Message";
import { NotificationManager } from "../../notification/NotificationManager";
import { PasswordInput } from "../../password/PasswordInput";

import { RegisterStore } from "../../../stores/RegisterStore";

import "../../../assets/grid-template.scss";

const moreThanTwoWordsRegex = new RegExp(".+ .+");
const hasMoreThanTwoWords = (str: string) => {
  return moreThanTwoWordsRegex.test(str);
};

export interface UserInput {
  name: string;
  nickname: string;
  phone: string;
  cpf: string;
  picture: string;
  pictureFile?: any;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  facebookID?: string;
  googleID?: string;
}

export interface ProfileInput {
  birthDate: string;
  address: string;
  neighborhood: string;
  complement: string;
}

interface UserStepProps {
  register?: RegisterStore;
  onChange: (name: keyof UserInput, value: string) => void;
  onCancel: (event: React.MouseEvent) => void;
  user: UserInput;
  next: () => void;
}

const regex = /\D/g;

const hint: any = {};

@inject("register")
@observer
export class UserStep extends Component<UserStepProps> {
  @observable verifyingValidEmail: boolean = false;

  @observable pastedEmail: boolean = false;

  @observable errors: any = {};

  @computed get isFormInvalid(): boolean {
    return Object.keys(this.errors).some((fieldName: keyof UserInput) => {
      return this.errors[fieldName].length > 0;
    });
  }

  @action
  validateField = (fieldName: keyof UserInput) => {
    switch (fieldName) {
      case "name":
        this.errors.name = [];
        if (this.props.user.name === "") {
          this.errors.name.push("Campo obrigatório");
        }

        if (hasMoreThanTwoWords(this.props.user.name) === false) {
          this.errors.name.push("Nome inválido");
        }

        break;
      case "email":
        this.errors.email = [];
        if (this.props.user.email === "") {
          this.errors.email.push("Campo obrigatório");
        }

        const emailRegex = new RegExp("[^\n]+@[^\n]+\\.[^\n]+");
        if (emailRegex.test(this.props.user.email) === false) {
          this.errors.email.push("E-mail inválido");
        }

        if (this.props.user.confirmEmail !== "") {
          this.validateField("confirmEmail");
        }

        break;
      case "confirmEmail":
        this.errors.confirmEmail = [];
        if (this.props.user.confirmEmail === "") {
          this.errors.confirmEmail.push("Campo obrigatório");
        }

        if (this.props.user.email !== this.props.user.confirmEmail) {
          this.errors.confirmEmail.push("Os e-mails não são iguais");
        }

        break;
      case "password":
        this.errors.password = [];
        if (this.props.user.password === "") {
          this.errors.password.push("Campo obrigatório");
        }

        if (this.props.user.confirmPassword !== "") {
          this.validateField("confirmPassword");
        }

        break;
      case "confirmPassword":
        this.errors.confirmPassword = [];
        if (this.props.user.confirmPassword === "") {
          this.errors.confirmPassword.push("Campo obrigatório");
        }

        if (this.props.user.password !== this.props.user.confirmPassword) {
          this.errors.confirmPassword.push("As senhas não são iguais");
        }

        break;
    }
  };

  @action
  validateForm = () => {
    Object.keys(this.props.user).forEach((fieldName: keyof UserInput) => {
      this.validateField(fieldName);
    });
  };

  @action
  onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    this.validateForm();

    const { props } = this;
    const { register: registerStore, user } = props;

    if (this.isFormInvalid) {
      return;
    }

    this.verifyingValidEmail = true;
    const isValid = await registerStore.verifyValidEmail(user.email);

    if (isValid.success === false) {
      const { errors } = isValid;

      NotificationManager.error({
        title: "Não foi possível verificar o e-mail",
        message: errors[0],
      });

      this.verifyingValidEmail = false;
      return;
    }

    if (isValid) {
      this.afterVerifyEmail();
    } else {
      this.onErrorVerifyEmail();
    }
  };

  @action
  afterVerifyEmail = () => {
    this.verifyingValidEmail = false;
    this.props.next();
  };

  @action
  onErrorVerifyEmail = () => {
    this.verifyingValidEmail = false;

    const message = `O e-mail informado já está cadastrado. Caso não lembre sua
      senha, vá até a página de autenticação e clique em "Esqueci minha senha".
    `;

    NotificationManager.error({
      title: "E-mail já cadastrado",
      message,
    });
  };

  @action
  onChange = (name: keyof UserInput, value: string) => {
    if (name == "confirmEmail" && this.pastedEmail) {
      this.pastedEmail = false;
      return;
    }

    this.props.onChange(name, value);
    this.validateField(name);
  };

  @action
  onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    this.pastedEmail = true;
    event.cancelable = false;
  };

  isFieldValid = (fieldName: keyof UserInput): boolean => {
    const errors = this.errors[fieldName];
    return !(errors && errors.length > 0);
  };

  getFieldStatus = (fieldName: keyof UserInput): string => {
    if (!this.isFieldValid(fieldName)) {
      return "error";
    }

    return "";
  };

  renderFieldMessage = (fieldName: string) => {
    const errors = this.errors[fieldName];
    const error = errors && this.errors[fieldName].length > 0 && errors[0];

    if (error) {
      return <Form.Message type={MessageType.Error}>{error}</Form.Message>;
    }

    const hintMsg = hint[fieldName];

    if (hintMsg) {
      return <Form.Message type={MessageType.Hint}>{hintMsg}</Form.Message>;
    }

    return null;
  };

  render() {
    const confirmPasswordClass = `col-12 col-sm-6 ${this.getFieldStatus(
      "confirmPassword"
    )}`;

    return (
      <div className="user-form">
        <h1 className="step-title">Cadastrar usuário</h1>
        <h3 className="step-sub-title">
          Insira seus dados pessoais para poder se cadastrar e se surpreender
          com os benefícios de uma plataforma de gestão em alimentação escolar.
        </h3>
        <Form>
          <Form.Field
            className={`col-12 col-sm-6 ${this.getFieldStatus("name")}`}
          >
            <Form.Label required={true} isValid={this.isFieldValid("name")}>
              Nome completo do responsável
            </Form.Label>
            <Input
              name="name"
              value={this.props.user.name}
              onChange={this.onChange}
              autoFocus={true}
            />
            {this.renderFieldMessage("name")}
          </Form.Field>
          <Form.Field
            className={`col-12 col-sm-6 ${this.getFieldStatus("nickname")}`}
          >
            <Form.Label isValid={this.isFieldValid("nickname")}>
              Apelido
            </Form.Label>
            <Input
              name="nickname"
              placeholder="Como gostaria de ser chamado"
              value={this.props.user.nickname}
              onChange={this.onChange}
            />
            {this.renderFieldMessage("nickname")}
          </Form.Field>
          <Form.Field
            className={`col-12 col-sm-6 ${this.getFieldStatus("email")}`}
          >
            <Form.Label required={true} isValid={this.isFieldValid("email")}>
              E-mail
            </Form.Label>
            <Input
              name="email"
              value={this.props.user.email}
              onChange={this.onChange}
              inputMode="email"
              placeholder="Informe seu melhor e-mail"
              autoCapitalize="none"
              autoCorrect="none"
              autoComplete="email"
            />
            {this.renderFieldMessage("email")}
          </Form.Field>
          <Form.Field
            className={`col-12 col-sm-6 ${this.getFieldStatus("confirmEmail")}`}
          >
            <Form.Label
              required={true}
              isValid={this.isFieldValid("confirmEmail")}
            >
              Confirmar e-mail
            </Form.Label>
            <Input
              name="confirmEmail"
              value={this.props.user.confirmEmail}
              onChange={this.onChange}
              onPaste={this.onPaste}
              inputMode="email"
              autoCapitalize="none"
              autoCorrect="none"
              autoComplete="email"
            />
            {this.renderFieldMessage("confirmEmail")}
          </Form.Field>
          <Form.Field
            className={`col-12 col-sm-6 ${this.getFieldStatus("password")}`}
          >
            <Form.Label required={true} isValid={this.isFieldValid("password")}>
              Senha
            </Form.Label>
            <PasswordInput
              name="password"
              placeholder="Digite uma senha"
              value={this.props.user.password}
              onChange={this.onChange}
            />
            {this.renderFieldMessage("password")}
          </Form.Field>
          <Form.Field className={confirmPasswordClass}>
            <Form.Label
              required={true}
              isValid={this.isFieldValid("confirmPassword")}
            >
              Confirmar senha
            </Form.Label>
            <PasswordInput
              name="confirmPassword"
              value={this.props.user.confirmPassword}
              onChange={this.onChange}
            />
            {this.renderFieldMessage("confirmPassword")}
          </Form.Field>
          <Form.Field className="col-12 step-buttons">
            <Button onClick={this.props.onCancel}>Cancelar</Button>
            <LoadingOverlay isLoading={this.verifyingValidEmail}>
              <Button
                className="facebook"
                submit
                onClick={this.onSubmit}
                disabled={this.isFormInvalid}
              >
                Continuar
              </Button>
            </LoadingOverlay>
          </Form.Field>
        </Form>
      </div>
    );
  }
}
