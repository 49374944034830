import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import './StepGroup.scss';

interface StepGroupProps {

}

@observer
export class StepGroup extends Component<StepGroupProps> {

  render() {
    const { props } = this;
    const { children } = props;

    return (
      <div className="step-group">
        { children }
      </div>
    );
  }

}
