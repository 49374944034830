import * as React from 'react';
import { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';

import { Button } from '../../../button/Button';
import { Form } from '../../../form/Form';
import { Input } from '../../../input/Input';
import { LoadingOverlay } from '../../../loading/LoadingOverlay';
import { MessageType } from '../../../form/Message';
import { NotificationManager } from '../../../notification/NotificationManager';

import { forgetPasswordAPI } from '../../../../api/ForgetPasswordAPI';

import '../../../../assets/grid-template.scss';

interface GetEmailStepProps {
  email: string;
  onCloseModal: () => void;
  onChangeEmail: (email: string) => void;
  onEmailSent: () => void;
}

@observer
export class GetEmailStep extends Component<GetEmailStepProps> {

  @observable isSendingEmail: boolean = false;

  @observable errors: string[] = [];

  @computed
  get emailStatus() {
    return this.isEmailValid ? '' : 'error';
  }

  @computed
  get isEmailValid() {
    return this.errors.length === 0;
  }

  @action
  onChangeEmail = (_: string, email: string) => {
    this.props.onChangeEmail(email);
    this.validateEmail(email);
  }

  @action
  submitGetEmailStep = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    const { props } = this;
    const { email } = props;

    this.validateEmail(email);

    if (!this.isEmailValid) {
      return;
    }


    try {
      this.isSendingEmail = true;
      const response = await forgetPasswordAPI.requestEmail(email);
      this.afterSendEmail(response);
    } catch (error) {
      this.onSendEmailError(error);
    }
  }

  @action
  afterSendEmail = (_: any) => {
    this.isSendingEmail = false;
    this.props.onEmailSent();
  }

  @action
  onSendEmailError = (error: AxiosError) => {
    this.isSendingEmail = false;
    const message = `
      Ocorreu um erro ao tentar enviar o e-mail para recuperação de senha.
    `;

    NotificationManager.error({
      title: 'Erro ao enviar e-mail',
      message,
    });
  }

  @action
  validateEmail = (email: string) => {
    const errors: string[] = [];

    if (email === '') {
      errors.push('Campo obrigatório');
    }

    const emailRegex = new RegExp('[^\n]+@[^\n]+\\.[^\n]+');
    if (emailRegex.test(email) === false) {
      errors.push('E-mail inválido');
    }

    this.errors = errors;
  }

  @action
  closeModal = () => {
    this.props.onCloseModal();
  }

  renderErrorMessage = () => {
    const error = this.errors
      && this.errors.length > 0
      && this.errors[0];

    if (error) {
      return <Form.Message type={MessageType.Error}>{ error }</Form.Message>;
    }

    return null;
  }

  render() {
    return (
      <Form>
        <Form.Field className="message col-12">
          Preencha seu e-mail no campo abaixo e, caso o e-mail esteja
          cadastrado, você receberá as instruções para alterar sua senha.
        </Form.Field>
        <Form.Field className={`col-12 ${this.emailStatus}`}>
          <Form.Label
            required={true}
            isValid={this.isEmailValid}
          >
            E-mail
          </Form.Label>
          <Input
            value={this.props.email}
            name="email"
            onChange={this.onChangeEmail}
            autoFocus={true}
            inputMode="email"
            autoCapitalize="none"
            autoCorrect="none"
            autoComplete="email"
          />
          { this.renderErrorMessage() }
        </Form.Field>
        <Form.Field className="col-12 buttons">
          <Button
            onClick={this.closeModal}
          >
            Cancelar
          </Button>
          <LoadingOverlay isLoading={this.isSendingEmail}>
            <Button
              submit
              onClick={this.submitGetEmailStep}
              className="primary"
              disabled={!this.isEmailValid}
            >
              Enviar e-mail
            </Button>
          </LoadingOverlay>
        </Form.Field>
      </Form>
    );
  }

}
