import { action, observable } from "mobx";

export class ApplicationStore {

  @observable menuOpen: boolean = false;
  @observable serviceTermsOpen: boolean = false;

  @action
  toggleMenu = () => {
    this.menuOpen = !this.menuOpen;
  }

  @action
  toggleServiceTermsModal = () => {
    this.serviceTermsOpen = !this.serviceTermsOpen;
  }

}
