import * as loadjs from 'loadjs';
import jwt from 'jsonwebtoken';

declare const gapi: any;

const BUNDLE_NAME = 'Google';

const SCOPE = 'openid profile email';
const CLIENT_ID = '818708362649-udok8l2bms11h1frh98rgc8ta8keelq2';
const FULL_CLIENT_ID = `${CLIENT_ID}.apps.googleusercontent.com`;

export const CHROME_POPUP_TUTORIAL
  = 'https://support.google.com/chrome/answer/95472';

export const FIREFOX_POPUP_TUTORIAL = 'https://support.mozilla.org/pt-BR/kb/' +
  'bloqueador-de-pop-up-excecoes-e-solucoes-de-problemas';

function loadGoogleAPI() {
  return new Promise((resolve, reject) => {
    loadjs(['https://apis.google.com/js/api:client.js'], BUNDLE_NAME, () => {
      resolve(gapi);
    });
  });
}

export function getGoogleAPI(): Promise<any> {
  if (loadjs.isDefined(BUNDLE_NAME)) {
    return new Promise((resolve) => {
      resolve(gapi);
    });
  } else {
    return loadGoogleAPI();
  }
}

export async function getGoogleUserAccessToken(): Promise<any> {
  const googleAPI = await getGoogleAPI();

  return new Promise((resolve, reject) => {
    googleAPI.load('auth2', () => {
      gapi.auth2.authorize({
        client_id: FULL_CLIENT_ID,
        immediate: false,
        scope: SCOPE,
        response_type: 'permission id_token',
      }, (response: any) => {
        if (response.error) {
          reject(response.error);
        } else if (response.id_token) {
          resolve(response.id_token);
        }
      });
    });
  });
}

export function getGoogleUserInfo(token: string) {
  const user: any = jwt.decode(token);
  const { sub: id, name, email, picture } = user;

  return { id, name, email, picture };
}
