import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import { CircleLoading } from './CircleLoading';

import './LoadingOverlay.scss';

interface LoadingOverlayProps {
  isLoading: boolean;
}

@observer
export class LoadingOverlay extends Component<LoadingOverlayProps> {

  render() {
    const { props } = this;
    const { isLoading } = props;

    if (!isLoading) {
      return this.props.children;
    }

    return (
      <div className="n-loading-overlay">
        <div className="content">
          { this.props.children }
        </div>
        <div className="overlay">
          <CircleLoading />
        </div>
      </div>
    );
  }

}
