import * as React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Button } from '../../button/Button';
import { Icon } from '../../icon/Icon';

import './../../../assets/grid-template.scss';
import './DoneStep.scss';
import { UserStore } from '../../../stores/UserStore';
import { LoadingOverlay } from '../../loading/LoadingOverlay';

interface DoneStepProps {
  user?: UserStore;
  onFinishRegister: (event: React.MouseEvent) => void;
}

@observer
@inject('user')
export class DoneStep extends Component<DoneStepProps> {

  render() {
    return (
      <div className="done-step">
        {/* <img src="/src/assets/icons/congrats.svg" alt=""/> */}
        <h1 className="step-title">Cadastro concluído com sucesso</h1>
        <h3 className="step-sub-title">
          Agora você já pode aproveitar todas as vantagens do Cantina Web
          acessando nosso painel de controle.
        </h3>
        <div className="success-icon">
          <Icon icon="check-circle" solid />
        </div>
        <div className="panel-button">
          <LoadingOverlay isLoading={this.props.user.performingLogin}>
            <Button
              className="primary"
              onClick={this.props.onFinishRegister}
            >
              Acessar painel de controle
            </Button>
          </LoadingOverlay>
        </div>
      </div>
    );
  }

}
