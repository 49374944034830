import * as React from "react";
import { Component } from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AxiosError } from "axios";

import { Step } from "../../steps/Step";
import { NotificationManager } from "../../notification/NotificationManager";

import { UserStep } from "./UserStep";
import { AddStudentStep } from "./AddStudentStep";
import { DoneStep } from "./DoneStep";

import { FormStep, RegisterStore } from "../../../stores/RegisterStore";

import "./RegisterForm.scss";
import { CardActivationStep } from "./CardActivationStep";
import { LoadingOverlay } from "../../loading/LoadingOverlay";

interface RegisterFormProps {
  register?: RegisterStore;
  onFinishRegister: (event: React.MouseEvent) => void;
  onCancel: (event: React.MouseEvent) => void;
}

@inject("register")
@observer
export class RegisterForm extends Component<RegisterFormProps> {
  @observable isSavingUser: boolean = false;

  @action
  register = async () => {
    const { props } = this;
    const { register: registerStore } = props;
    const { user, student, profile } = registerStore;

    try {
      this.isSavingUser = true;
      const response = await registerStore.register(user, student, profile);
      this.afterRegister(response);
    } catch (error) {
      this.onRegisterError(error);
    }
  };

  afterRegister = (_: any) => {
    const { props } = this;
    const { register: registerStore } = props;

    registerStore.goToNextStep();
    this.isSavingUser = false;
  };

  onRegisterError = (error: AxiosError) => {
    this.isSavingUser = false;
    const { response } = error;

    const message = response
      ? response.data
      : `
        Não foi possível realizar o cadastro no momento.
        Por favor, tente novamente mais tarde.
      `;

    NotificationManager.error({
      title: "Erro ao cadastrar",
      message,
    });
  };

  renderUserStep = () => {
    const { props } = this;
    const { register: registerStore } = props;

    return (
      <UserStep
        onCancel={this.props.onCancel}
        user={registerStore.user}
        onChange={registerStore.onChangeUserInput}
        next={registerStore.goToNextStep}
      />
    );
  };

  renderStudentStep = () => {
    const { props } = this;
    const { register: registerStore } = props;

    return (
      <AddStudentStep
        userInput={registerStore.user}
        studentInput={registerStore.student}
        onChange={registerStore.onChangeStudentInput}
        prev={registerStore.goToPreviousStep}
        next={this.register}
      />
    );
  };

  renderCardActivationStep = () => {
    const { props } = this;
    const { register: registerStore } = props;

    return (
      <CardActivationStep
        userInput={registerStore.user}
        studentInput={registerStore.student}
        profileInput={registerStore.profile}
        onChange={registerStore.onChangeStudentInput}
        prev={registerStore.goToPreviousStep}
        next={registerStore.goToNextStep}
      />
    );
  };

  renderDoneStep = () => (
    <DoneStep onFinishRegister={this.props.onFinishRegister} />
  );

  renderStepForm = () => {
    const { props } = this;
    const { register: registerStore } = props;

    switch (registerStore.formStep) {
      case FormStep.User:
        return this.renderUserStep();
      case FormStep.Student:
        return this.renderStudentStep();
      case FormStep.Card:
        return this.renderCardActivationStep();
      case FormStep.Done:
        return this.renderDoneStep();
    }
  };

  render() {
    const { props } = this;
    const { register: registerStore } = props;

    return (
      <LoadingOverlay isLoading={this.isSavingUser}>
        <div className="register-form">
          <Step.Group>
            <Step
              active={registerStore.isUserActive}
              completed={registerStore.isUserCompleted}
              title="Acesso"
              icon="/public/network.svg"
            />

            <Step
              active={registerStore.isCardActive}
              completed={registerStore.isCardCompleted}
              title="Cartão"
              icon="/public/card.svg"
            />

            <Step
              active={registerStore.isStudentActive}
              completed={registerStore.isStudentCompleted}
              title="Aluno"
              icon="/public/alumni.svg"
            />
          </Step.Group>
          {this.renderStepForm()}
          {/* {this.renderDoneStep()} */}
        </div>
      </LoadingOverlay>
    );
  }
}
