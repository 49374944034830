import { action, computed, observable } from "mobx";

import { RegisterAPI, CheckStudentResponse } from "../api/RegisterAPI";
import { UserInput, ProfileInput } from "../components/home/register/UserStep";
import { StudentInput } from "../components/home/register/AddStudentStep";

function getImagePath(file: any) {
  return URL.createObjectURL(file);
}

function getEmptyUserInput(): UserInput {
  return {
    name: "",
    nickname: "",
    phone: "",
    picture: null,
    cpf: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  };
}

function getEmptyStudentInput(): StudentInput {
  return {
    name: "",
    identifier: "",
    birthDate: "",
    cardCode: "",
    organization: null,
  };
}

function getEmptyProfileInput(): ProfileInput {
  return {
    birthDate: "",
    address: "",
    neighborhood: "",
    complement: "",
  };
}

export enum FormStep {
  User,
  Student,
  Card,
  Done,
}

const nextStepMap = {
  [FormStep.User]: FormStep.Card,
  [FormStep.Card]: FormStep.Student,
  [FormStep.Student]: FormStep.Done,
  [FormStep.Done]: FormStep.Done,
};

const previousStepMap = {
  [FormStep.User]: FormStep.User,
  [FormStep.Card]: FormStep.User,
  [FormStep.Student]: FormStep.Card,
  [FormStep.Done]: FormStep.Card,
};

export class RegisterStore {
  @observable isFormOpen: boolean = false;
  @observable formStep: FormStep;
  @observable user: UserInput;
  @observable student: StudentInput;
  @observable profile: ProfileInput;
  @observable initialStudent: StudentInput;

  @computed get isUserCompleted() {
    return this.formStep > FormStep.User;
  }

  @computed get isStudentCompleted() {
    return this.formStep > FormStep.Student;
  }

  @computed get isCardCompleted() {
    return this.formStep > FormStep.Card;
  }

  @computed get isUserActive() {
    return this.formStep === FormStep.User;
  }

  @computed get isStudentActive() {
    return this.formStep === FormStep.Student;
  }

  @computed get isCardActive() {
    return this.formStep === FormStep.Card;
  }

  constructor(private api: RegisterAPI) {
    this.resetForm();
  }

  @action
  goToNextStep = () => {
    this.formStep = nextStepMap[this.formStep];
  };

  @action
  goToPreviousStep = () => {
    this.formStep = previousStepMap[this.formStep];
  };

  @action
  onChangeUserImageInput = (_: string, value: File) => {
    this.user.picture = getImagePath(value);
    this.user.pictureFile = value;
  };

  @action
  onChangeUserInput = (name: keyof UserInput, value: string) => {
    this.user[name] = value;
  };

  @action
  onChangeStudentInput = (name: keyof StudentInput, value: string) => {
    this.student[name] = value;
  };

  @action
  onChangeProfileInput = (name: keyof ProfileInput, value: string) => {
    this.profile[name] = value;
  };

  @action
  setIsFormOpen = (isOpen: boolean) => {
    this.isFormOpen = isOpen;
  };

  @action
  register = async (
    user: UserInput,
    student: StudentInput,
    profile: ProfileInput
  ) => {
    return await this.api.register(
      {
        ...user,
        name: user.name.trim(),
      },
      student,
      profile
    );
  };

  @action
  verifyValidEmail = async (email: string) => {
    return this.api.verifyValidEmail(email);
  };

  @action
  resetForm = () => {
    this.formStep = FormStep.User;
    this.user = getEmptyUserInput();
    this.student = getEmptyStudentInput();
    this.profile = getEmptyProfileInput();
  };

  @action
  activateStudentCard = async (cardCode: string) => {
    return await this.api.activateStudentCard(cardCode);
  };

  @action
  setStudent = (student: StudentInput) => {
    this.initialStudent = student;
    this.student = student;
  };
}
