import * as React from "react";
import { Component } from "react";
import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import { AxiosError } from "axios";

import { Button } from "../../../button/Button";
import { Form } from "../../../form/Form";
import { LoadingOverlay } from "../../../loading/LoadingOverlay";
import { MessageType } from "../../../form/Message";
import { NotificationManager } from "../../../notification/NotificationManager";
import { PasswordInput } from "../../../password/PasswordInput";

import { forgetPasswordAPI } from "../../../../api/ForgetPasswordAPI";

import "../../../../assets/grid-template.scss";

interface ChangePasswordStepProps {
  email: string;
  token: string;
  goToStepConfirmToken: () => void;
  onPasswordChanged: () => void;
}

@observer
export class ChangePasswordStep extends Component<ChangePasswordStepProps> {
  @observable isChangingPassword: boolean = false;

  @observable errors: string[] = [];
  @observable password: string = "";
  @observable confirmPassword: string = "";

  @computed
  get passwordStatus() {
    return this.isPasswordValid ? "" : "error";
  }

  @computed
  get isPasswordValid() {
    return this.errors.length === 0;
  }

  @action
  onChangePassword = (_: string, password: string) => {
    this.password = password;
    this.validatePassword();
  };

  onChangeConfirmPassword = (_: string, confirmPassword: string) => {
    this.confirmPassword = confirmPassword;
    this.validateNewPassword();
  };

  @action
  submitChangePassword = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    this.validatePassword();
    this.validateNewPassword();

    if (!this.isPasswordValid) {
      return;
    }

    const { props, password, confirmPassword } = this;
    const { token, email } = props;

    try {
      this.isChangingPassword = true;
      const response = await forgetPasswordAPI.changePassword(
        token,
        email,
        password,
        confirmPassword
      );
      this.afterChangePassword(response);
    } catch (error) {
      this.onChangePasswordError(error);
    }
  };

  @action
  afterChangePassword = (_: any) => {
    this.isChangingPassword = false;
    this.props.onPasswordChanged();
  };

  @action
  onChangePasswordError = (error: AxiosError) => {
    this.isChangingPassword = false;
    const message = `
      Ocorreu um erro ao alterar sua senha, tente novamente.
    `;

    NotificationManager.error({
      title: "Erro ao alterar senha",
      message,
    });
  };

  @action
  validatePassword = () => {
    const { password, confirmPassword } = this;
    const errors: string[] = [];

    if (password === "") {
      errors.push("Campo obrigatório");
    }

    this.errors = errors;
  };

  @action
  validateNewPassword = () => {
    const { password, confirmPassword } = this;
    const errors: string[] = [];

    if (password !== confirmPassword) {
      errors.push("As senhas não são iguais");
    }

    this.errors = errors;
  };

  renderErrorMessage = () => {
    const { password } = this;
    const error = this.errors && this.errors.length > 0 && this.errors[0];

    if (error && password === "") {
      return <Form.Message type={MessageType.Error}>{error}</Form.Message>;
    }

    return null;
  };

  renderConfirmErrorMessage = () => {
    const error = this.errors && this.errors.length > 0 && this.errors[0];

    if (error && this.password !== "") {
      return <Form.Message type={MessageType.Error}>{error}</Form.Message>;
    }

    return null;
  };

  render() {
    return (
      <Form>
        <Form.Field className={`col-12 ${this.passwordStatus}`}>
          <Form.Label required={true} isValid={this.isPasswordValid}>
            Nova senha
          </Form.Label>

          <PasswordInput
            value={this.password}
            name="password"
            onChange={this.onChangePassword}
            autoFocus={true}
            autoCapitalize="none"
            autoCorrect="none"
            autoComplete="off"
          />

          {this.renderErrorMessage()}
        </Form.Field>

        <Form.Field className={`col-12 ${this.passwordStatus}`}>
          <Form.Label required={true} isValid={this.isPasswordValid}>
            Confirmar senha
          </Form.Label>

          <PasswordInput
            value={this.confirmPassword}
            name="confirmPassword"
            onChange={this.onChangeConfirmPassword}
            autoFocus={false}
            autoCapitalize="none"
            autoCorrect="none"
            autoComplete="off"
          />

          {this.renderConfirmErrorMessage()}
        </Form.Field>

        <Form.Field className="col-12 buttons">
          <Button onClick={this.props.goToStepConfirmToken}>Voltar</Button>
          <LoadingOverlay isLoading={this.isChangingPassword}>
            <Button
              submit
              onClick={this.submitChangePassword}
              className="primary"
              disabled={!this.isPasswordValid}
            >
              Alterar senha
            </Button>
          </LoadingOverlay>
        </Form.Field>
      </Form>
    );
  }
}
