import * as React from "react";
import { Component } from "react";
import { action, computed, observable } from "mobx";
import { observer, inject } from "mobx-react";
import { createNumberMask } from "text-mask-addons";

import { Button } from "../../button/Button";
import { Input } from "../../input/Input";
import { LoadingOverlay } from "../../loading/LoadingOverlay";
import { Form } from "../../form/Form";
import { MessageType } from "../../form/Message";
import { NotificationManager } from "../../notification/NotificationManager";

import { UserInput, ProfileInput } from "./UserStep";
import { RegisterStore } from "../../../stores/RegisterStore";
import { isValidDate } from "../../../utils/date";

import "../../../assets/grid-template.scss";

const numberMaskProps = {
  mask: createNumberMask({
    prefix: "",
    suffix: "",
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
  }),
};

export interface StudentInput {
  name: string;
  identifier: string;
  birthDate: string;
  organization: string;
  cardCode: string;
}

interface CardActivationStepProps {
  studentInput: StudentInput;
  userInput: UserInput;
  profileInput: ProfileInput;
  register?: RegisterStore;
  onChange: (name: keyof StudentInput, value: string) => void;
  prev: () => void;
  next: () => void;
}

const hint: any = {
  identifier: `
    O mesmo número do R.A. (Registro Acadêmico), normalmente disponível
    na carteirinha da escola
  `,
};

@inject("register")
@observer
export class CardActivationStep extends Component<CardActivationStepProps> {
  @observable organizationOptions: any[] = [];
  @observable needToSelectOrganization: boolean = false;
  @observable waiting: boolean = false;
  @observable collapse: boolean = window.screen.width > 700 ? false : true;

  @observable errors: any = {};

  @computed get isFormInvalid(): boolean {
    return Object.keys(this.errors).some((fieldName: keyof StudentInput) => {
      return this.errors[fieldName].length > 0;
    });
  }

  @action
  validateField = (fieldName: keyof StudentInput) => {
    switch (fieldName) {
      case "name":
        this.errors.name = [];
        if (this.props.studentInput.name === "") {
          this.errors.name.push("Campo obrigatório");
        }

        break;
      case "identifier":
        this.errors.identifier = [];
        if (this.props.studentInput.identifier === "") {
          this.errors.identifier.push("Campo obrigatório");
        }

        break;
      case "birthDate":
        this.errors.birthDate = [];
        const { birthDate } = this.props.studentInput;

        if (birthDate === "") {
          this.errors.birthDate.push("Campo obrigatório");
        }

        if (!isValidDate(birthDate, "01/01/1900", "31/12/2019")) {
          this.errors.birthDate.push("Data inválida");
        }

        break;
      case "organization":
        this.errors.organization = [];

        if (this.needToSelectOrganization) {
          if (this.props.studentInput.organization === "") {
            this.errors.organization.push("Campo obrigatório");
          }
        }

        break;
    }
  };

  @action
  validateForm = () => {
    const fieldNames = Object.keys(this.props.studentInput);
    fieldNames.forEach((fieldName: keyof StudentInput) => {
      this.validateField(fieldName);
    });
  };

  @action
  onChange = (name: keyof StudentInput, value: string) => {
    this.props.onChange(name, value);
    this.validateField(name);
  };

  @action
  clearSpacesOnStudentName = () => {
    const { props } = this;
    const { studentInput } = props;
    const { name } = studentInput;

    const clearedName = name
      .split(" ")
      .filter((namePart) => {
        return namePart.length > 0;
      })
      .join(" ");

    this.onChange("name", clearedName);
  };

  @action
  afterCheckStudent = (response: any) => {
    this.waiting = false;

    if (response.valid === false) {
      const message = `
        Não foi encontrado aluno com os dados informados.
        Verifique os dados e tente novamente.
      `;
      NotificationManager.error({
        title: "Aluno não encontrado",
        message,
      });
      return;
    }

    if (response.companies.length > 1) {
      this.needToSelectOrganization = true;
      this.organizationOptions = response.companies.map((company: any) => {
        return {
          value: company.id,
          label: company.name,
        };
      });

      const message = `
        Não foi possível identificar o aluno. Selecione a instituição do aluno.
      `;
      NotificationManager.error({
        title: "Aluno não encontrado",
        message,
      });
      return;
    }

    this.props.next();
  };

  isFieldValid = (fieldName: keyof StudentInput): boolean => {
    const errors = this.errors[fieldName];
    return !(errors && errors.length > 0);
  };

  getFieldStatus = (fieldName: keyof StudentInput): string => {
    if (!this.isFieldValid(fieldName)) {
      return "error";
    }

    return "";
  };

  renderFieldMessage = (fieldName: string) => {
    const errors = this.errors[fieldName];
    const error = errors && this.errors[fieldName].length > 0 && errors[0];

    if (error) {
      return <Form.Message type={MessageType.Error}>{error}</Form.Message>;
    }

    const hintMsg = hint[fieldName];

    if (hintMsg) {
      return <Form.Message type={MessageType.Hint}>{hintMsg}</Form.Message>;
    }

    return null;
  };

  @action
  activateStudentCard = async () => {
    this.waiting = true;
    const { props } = this;
    const { studentInput: student, register: registerStore } = props;

    try {
      var response = await registerStore.activateStudentCard(student.cardCode);

      this.afterActivateStudentCard(response);
    } catch (error) {
      this.afterActivateStudentCard(response);
    }
  };

  @action
  afterActivateStudentCard = (response: any) => {
    const { setStudent } = this.props.register;

    if (response.status == 200) {
      const { data } = response.data;

      setStudent({
        name: data.studentName,
        identifier: data.identifier,
        birthDate: data.birthday,
        organization: data.school.name,
        cardCode: data.cardNumber,
      });

      this.waiting = false;
      this.props.next();
    } else {
      NotificationManager.error({
        title: "Não foi possível ativar seu cartão =(",
        message: this.createMessage(response),
      });
      this.waiting = false;
    }
  };

  createMessage = (response: any) => {
    if (response.data) return response.data.message;

    return "Um erro inesperado ocorreu, por favor, aguarda um instante e tente novamente";
  };

  readMore = () => {
    const handleText = this.collapse ? (
      <strong
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => (this.collapse = !this.collapse)}
      >
        {" "}
        ...Leia mais
      </strong>
    ) : (
      <>
        {" "}
        ou superior a R$100,00 (Cem reais) por filho, é isento o valor da taxa
        do cartão!{" "}
        {window.screen.width < 700 && (
          <strong
            onClick={() => (this.collapse = !this.collapse)}
            style={{ color: "blue" }}
          >
            Mostrar menos
          </strong>
        )}
      </>
    );

    return (
      <p>
        Você sabia: A <strong>Primeira</strong> recarga feita de valor igual
        {handleText}
      </p>
    );
  };

  render() {
    const { props } = this;
    const { userInput } = props;
    const { name: userName, nickname } = userInput;

    const orgFieldClass = this.needToSelectOrganization ? "" : "hidden";
    const organizationFieldClasses = `
      col-12 ${orgFieldClass} ${this.getFieldStatus("organization")}
    `;

    const backButton =
      this.props.userInput.facebookID ||
      this.props.userInput.googleID ? null : (
        <Button onClick={this.props.prev}>Voltar</Button>
      );

    return (
      <div className="add-student-step">
        <h1 className="step-title">Associar o cartão</h1>
        <h3 className="step-sub-title">
          Legal, <b>{nickname || userName}</b>, para realizar compras no
          terminal, você precisará do cartão CantinaWeb, que poderá ser retirado
          na cantina da escola. Para associá-lo informe o código no campo
          abaixo:
        </h3>

        <div className="inform-recharge">
          <img src={require("../../../assets/images/lamp.png")} />

          {this.readMore()}
        </div>

        <Form>
          <Form.Field className={`col-12 ${this.getFieldStatus("cardCode")}`}>
            <Form.Label
              required={false}
              isValid={this.isFieldValid("cardCode")}
            >
              Código do cartão
            </Form.Label>
            <Input
              name="cardCode"
              value={this.props.studentInput.cardCode}
              onChange={this.onChange}
              inputMode="numeric"
              maxLength={35}
            />
            {this.renderFieldMessage("cardCode")}
          </Form.Field>
          <div className="step-buttons-div">
            <img
              className="card-img"
              src={require("../../../assets/images/card-n.png")}
            />

            <Form.Field className="col-12 step-buttons">
              {backButton}
              <LoadingOverlay isLoading={this.waiting}>
                <Button className="facebook" onClick={this.activateStudentCard}>
                  Continuar
                </Button>
              </LoadingOverlay>
            </Form.Field>
          </div>
          <small className="footer-hint">
            <strong>Dica:</strong>
            <span>
              &nbsp; Você poderá associar mais de um aluno ao seu cadastro assim
              que concluí-lo.
            </span>
          </small>
        </Form>
      </div>
    );
  }
}
