import axios from 'axios';

export class UserAPI {

  login = async (email: string, password: string): Promise<any> => {
    const response = await axios.post("/v2/account/login",{
        userName: email,
        password,
      }
    );

    if (response.data.authenticated === false) {
      return {
        authenticated: false,
      };
    }

    return response.data;
  }

  loginFacebook = async (token: string) => {
    try {
      const response = await axios.post('/Accounts/LoginFB', { token });
      return response.data;
    } catch (error) {
      const { code: errorCode } = error.response.data;

      if (errorCode === '404') {
        return {
          authenticated: false,
          error: 'not_registered',
        };
      } else {
        return {
          authenticated: false,
        };
      }
    }
  }

  loginGoogle = async (token: string) => {
    try {
      const response = await axios.post('/Accounts/LoginGoogle', { token });
      return response.data;
    } catch (error) {
      const { code: errorCode } = error.response.data;

      if (errorCode === '404') {
        return {
          authenticated: false,
          error: 'not_registered',
        };
      } else {
        return {
          authenticated: false,
        };
      }
    }
  }

}
