import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import './Input.scss';

export interface InputProps {
  id?: string;
  type: string;
  name: string;
  value: string;
  readOnly: boolean;
  disabled: boolean;
  onChange?: (name: string, value: string) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  className: string;
  placeholder?: string;
  icon?: string;
  maxLength?: number;
  maskProps?: MaskedInputProps;
  inputMode?: string;
  autoFocus?: boolean;
  autoCapitalize?: string;
  autoCorrect?: string;
  autoComplete?: string;
  label?: React.ReactNode;
}

@observer
export class Input extends Component<InputProps> {

  static defaultProps = {
    type: 'text',
    readOnly: false,
    disabled: false,
    className: '',
  };

  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { props } = this;
    const { name, onChange } = props;

    onChange(name, event.currentTarget.value);
  }

  renderLabel = () => {
    const { props } = this;
    const { label }  = props;

    if (!label) {
      return;
    }

    return (
      <div className="label">
        { label }
      </div>
    );
  }

  render() {
    const disabledClass = this.props.disabled ? 'disabled' : '';

    const props = {
      id: this.props.id,
      type: this.props.type,
      name: this.props.name,
      placeholder: this.props.placeholder,
      value: this.props.value,
      readOnly: this.props.readOnly,
      disabled: this.props.disabled,
      onChange: this.onChange,
      onClick: this.props.onClick,
      onFocus: this.props.onFocus,
      onBlur: this.props.onBlur,
      onKeyDown: this.props.onKeyDown,
      onPaste: this.props.onPaste,
      inputMode: this.props.inputMode,
      autoFocus: this.props.autoFocus,
      autoCapitalize: this.props.autoCapitalize,
      autoCorrect: this.props.autoCorrect,
      autoComplete: this.props.autoComplete,
      maxLength: this.props.maxLength
    };

    const input = Boolean(this.props.maskProps) ? (
      <MaskedInput
        { ...props }
        { ...this.props.maskProps }
      />
    ) : (
      <input { ...props } />
    );

    const labelClass = this.props.label ? 'label' : '';

    const classes = [
      'n-input',
      this.props.className,
      disabledClass,
      labelClass,
    ].join(' ');

    return (
      <div className={classes}>
        { this.renderLabel() }
        { input }
        { this.props.children }
      </div>
    );
  }

}
