import * as React from 'react';
import { Component } from 'react';

import { ButtonGroup } from './ButtonGroup';

import './Button.scss';

interface ButtonProps {
  className: string;
  disabled: boolean;
  onClick: (event: React.SyntheticEvent) => void;
  submit: boolean;
}

export class Button extends Component<ButtonProps> {

  static defaultProps = {
    className: '',
    disabled: false,
    submit: false,
    onClick: () => {},
  };

  static Group = ButtonGroup;

  onClick = (event: React.SyntheticEvent) => {
    const { props } = this;
    const { onClick } = props;

    onClick(event);
  }

  render() {
    const { props } = this;
    const { className } = props;

    const disabledClassName = this.props.disabled ? 'disabled' : '';
    const type = this.props.submit ? 'submit' : 'button';

    return (
      <button
        className={`button ${className} ${disabledClassName}`}
        onClick={this.onClick}
        type={type}
      >
        { this.props.children }
      </button>
    );
  }

}
