import * as React from "react";
import { Component } from "react";
import { action, computed, observable } from "mobx";
import { observer, inject } from "mobx-react";
import { createNumberMask } from "text-mask-addons";

import { BirthDateInput } from "../../birthdate-input/BirthDateInput";
import { Button } from "../../button/Button";
import { Input } from "../../input/Input";
import { LoadingOverlay } from "../../loading/LoadingOverlay";
import { Form } from "../../form/Form";
import { MessageType } from "../../form/Message";
import { NotificationManager } from "../../notification/NotificationManager";

import { UserInput } from "./UserStep";
import { RegisterStore } from "../../../stores/RegisterStore";
import { isValidDate } from "../../../utils/date";

import "../../../assets/grid-template.scss";
import "./AddStudentStep.scss";

const numberMaskProps = {
  mask: createNumberMask({
    prefix: "",
    suffix: "",
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
  }),
};

export interface StudentInput {
  name: string;
  identifier: string;
  birthDate: string;
  organization: string;
  cardCode: string;
}

interface AddStudentStepProps {
  studentInput: StudentInput;
  userInput: UserInput;
  register?: RegisterStore;
  onChange: (name: keyof StudentInput, value: string) => void;
  prev: () => void;
  next: () => void;
}

const hint: any = {
  identifier: `
    O mesmo número do R.A. (Registro Acadêmico), normalmente disponível
    na carteirinha da escola
  `,
};

@inject("register")
@observer
export class AddStudentStep extends Component<AddStudentStepProps> {
  @observable organizationOptions: any[] = [];
  @observable needToSelectOrganization: boolean = false;
  @observable checkingDuplicateStudent: boolean = false;

  @observable errors: any = {};

  @computed get isFormInvalid(): boolean {
    return Object.keys(this.errors).some((fieldName: keyof StudentInput) => {
      return this.errors[fieldName].length > 0;
    });
  }

  @action
  validateField = (fieldName: keyof StudentInput) => {
    switch (fieldName) {
      case "name":
        this.errors.name = [];
        if (this.props.studentInput.name === "") {
          this.errors.name.push("Campo obrigatório");
        }

        break;
      case "birthDate":
        this.errors.birthDate = [];
        const { birthDate } = this.props.studentInput;

        if (
          birthDate !== "" &&
          !isValidDate(birthDate, "01/01/1900", "31/12/2019")
        ) {
          this.errors.birthDate.push("Data inválida");
        }

        break;
    }
  };

  @action
  validateForm = () => {
    const fieldNames = Object.keys(this.props.studentInput);
    fieldNames.forEach((fieldName: keyof StudentInput) => {
      this.validateField(fieldName);
    });
  };

  @action
  onChange = (name: keyof StudentInput, value: string) => {
    this.props.onChange(name, value);
    this.validateField(name);
  };

  @action
  clearSpacesOnStudentName = () => {
    const { props } = this;
    const { studentInput } = props;
    const { name } = studentInput;

    const clearedName = name
      .split(" ")
      .filter((namePart) => {
        return namePart.length > 0;
      })
      .join(" ");

    this.onChange("name", clearedName);
  };

  @action
  onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    this.clearSpacesOnStudentName();
    this.validateForm();

    const { props } = this;
    const { studentInput, register: registerStore } = props;

    if (this.isFormInvalid) {
      return;
    }

    this.props.next();
  };

  @action
  afterCheckStudent = (response: any) => {
    this.checkingDuplicateStudent = false;

    if (response.valid === false) {
      const message = `
        Não foi encontrado aluno com os dados informados.
        Verifique os dados e tente novamente.
      `;
      NotificationManager.error({
        title: "Aluno não encontrado",
        message,
      });
      return;
    }

    if (response.companies.length > 1) {
      this.needToSelectOrganization = true;
      this.organizationOptions = response.companies.map((company: any) => {
        return {
          value: company.id,
          label: company.name,
        };
      });

      const message = `
        Não foi possível identificar o aluno. Selecione a instituição do aluno.
      `;
      NotificationManager.error({
        title: "Aluno não encontrado",
        message,
      });
      return;
    }

    this.props.next();
  };

  isFieldValid = (fieldName: keyof StudentInput): boolean => {
    const errors = this.errors[fieldName];
    return !(errors && errors.length > 0);
  };

  getFieldStatus = (fieldName: keyof StudentInput): string => {
    if (!this.isFieldValid(fieldName)) {
      return "error";
    }

    return "";
  };

  renderFieldMessage = (fieldName: string) => {
    const errors = this.errors[fieldName];
    const error = errors && this.errors[fieldName].length > 0 && errors[0];

    if (error) {
      return <Form.Message type={MessageType.Error}>{error}</Form.Message>;
    }

    const hintMsg = hint[fieldName];

    if (hintMsg) {
      return <Form.Message type={MessageType.Hint}>{hintMsg}</Form.Message>;
    }

    return null;
  };

  render() {
    const { props } = this;
    const { userInput, register } = props;
    const { name: userName, nickname } = userInput;
    const { initialStudent } = register;

    const orgFieldClass = this.needToSelectOrganization ? "" : "hidden";
    const organizationFieldClasses = `
      col-12 ${orgFieldClass} ${this.getFieldStatus("organization")}
    `;

    const backButton =
      this.props.userInput.facebookID ||
      this.props.userInput.googleID ? null : (
        <Button onClick={this.props.prev}>Voltar</Button>
      );

    return (
      <div className="add-student-step">
        <h1 className="step-title">Vincular aluno</h1>
        <h3 className="step-sub-title">
          Legal <b>{nickname || userName}</b>, agora informe qual aluno deseja
          acompanhar a gestão alimentar.
        </h3>
        <Form>
          <Form.Field className={`col-12 ${this.getFieldStatus("name")}`}>
            <Form.Label required={true} isValid={this.isFieldValid("name")}>
              Nome completo do aluno
            </Form.Label>
            <Input
              name="name"
              disabled={initialStudent.name !== ""}
              value={this.props.studentInput.name}
              onChange={this.onChange}
              autoFocus={true}
            />
            {this.renderFieldMessage("name")}
          </Form.Field>
          <Form.Field className={`col-6 ${this.getFieldStatus("identifier")}`}>
            <Form.Label isValid={this.isFieldValid("identifier")}>
              Matrícula {"(opcional)"}
            </Form.Label>
            <Input
              name="identifier"
              disabled={initialStudent.identifier !== ""}
              value={this.props.studentInput.identifier}
              onChange={this.onChange}
              inputMode="numeric"
              maxLength={15}
            />
            {this.renderFieldMessage("identifier")}
          </Form.Field>
          <Form.Field className={`col-6 ${this.getFieldStatus("birthDate")}`}>
            <Form.Label isValid={this.isFieldValid("birthDate")}>
              Data de nascimento
            </Form.Label>
            <BirthDateInput
              name="birthDate"
              disabled={initialStudent.birthDate !== ""}
              value={this.props.studentInput.birthDate}
              onChange={this.onChange}
            />
            {this.renderFieldMessage("birthDate")}
          </Form.Field>
          {/* <Form.Field className={organizationFieldClasses}>
            <Form.Label
              required={true}
              isValid={this.isFieldValid("organization")}
            >
              Instituição
            </Form.Label>
            <Autocomplete
              options={this.organizationOptions}
              name="organization"
              value={this.props.studentInput.organization}
              onChange={this.onChange}
            />
            {this.renderFieldMessage("organization")}
          </Form.Field> */}
          <Form.Field className="col-12 step-buttons">
            {backButton}
            <LoadingOverlay isLoading={this.checkingDuplicateStudent}>
              <Button
                className="facebook"
                submit
                onClick={this.onSubmit}
                disabled={this.isFormInvalid}
              >
                Continuar
              </Button>
            </LoadingOverlay>
          </Form.Field>
          <small className="footer-hint">
            <strong>Dica:</strong>
            <span>
              &nbsp; Você poderá associar mais de um aluno ao seu cadastro assim
              que concluí-lo.
            </span>
          </small>
        </Form>
      </div>
    );
  }
}
