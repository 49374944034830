import * as React from 'react';
import { action, observable, toJS } from 'mobx';

import { Notification } from './Notification';

interface NotificationI {
  title: React.ReactNode;
  message: React.ReactNode;
  className?: string;
  icon?: string;
  timeOut?: number;
}

let id = 0;

class NotificationManagerClass {

  @observable notifications: any[] = [];

  @action
  close = (id: number) => {
    const index = this.notifications.findIndex((notification) => {
      return id === notification.id;
    });

    if (index !== -1) {
      this.notifications.splice(index, 1);
    }
  }

  success = ({
    title, message, timeOut = 5000, className = '',
  }: NotificationI) => {
    return this.create({
      title,
      message,
      className: `success ${className}`,
      icon: 'check-circle',
      timeOut,
    });
  }

  warning = ({
    title, message, timeOut = 5000, className = '',
  }: NotificationI) => {
    return this.create({
      title,
      message,
      className: `warning ${className}`,
      icon: 'exclamation-triangle',
      timeOut,
    });
  }

  error = ({
    title, message, timeOut = 5000, className = '',
  }: NotificationI) => {
    return this.create({
      title,
      message,
      className: `danger ${className}`,
      icon: 'exclamation-circle',
      timeOut,
    });
  }

  info = ({
    title, message, timeOut = 5000, className = '',
  }: NotificationI) => {
    return this.create({
      title,
      message,
      className: `info ${className}`,
      icon: 'info-circle',
      timeOut,
    });
  }

  create = ({
    icon, title, message, timeOut = 5000, className = '',
  }: NotificationI) => {
    id += 1;

    const notification = {
      id,
      icon,
      title,
      message,
      className,
      timeOut,
      requestClose: this.close,
    };

    return this.new(id, <Notification {...notification} />);
  }

  @action
  new = (id: number, notification: React.ReactElement<Notification>) => {
    this.notifications.push({ id, notification });
    return { id };
  }

}

export const NotificationManager = new NotificationManagerClass();
