import * as React from 'react';
import { Component } from 'react';

import './ButtonGroup.scss';

interface ButtonGroupProps {
  className: string;
}

export class ButtonGroup extends Component<ButtonGroupProps> {

  static defaultProps = {
    className: '',
  };

  render() {
    const { props } = this;
    const { children, className } = props;

    return (
      <div className={`button-group ${className}`}>
        { children }
      </div>
    );
  }

}
