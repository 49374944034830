import * as loadjs from 'loadjs';

declare const FB: any;

const BUNDLE_NAME = 'Facebook';

const appID = '202155587329649';

function loadFacebookAPI() {
  return new Promise((resolve) => {
    loadjs(['https://connect.facebook.net/en_US/sdk.js'], BUNDLE_NAME, () => {
      FB.init({
        appId: appID,
        xfbml: false,
        version: 'v3.2',
      });
      resolve(FB);
    });
  });
}

export function getFacebookAPI(): Promise<any> {
  if (loadjs.isDefined(BUNDLE_NAME)) {
    return new Promise((resolve) => {
      resolve(FB);
    });
  } else {
    return loadFacebookAPI();
  }
}

export async function getFBUserAccessToken(): Promise<string> {
  const fbAPI = await getFacebookAPI();

  return new Promise((resolve, reject) => {
    fbAPI.login((response: any) => {
      if (response.status === 'connected') {
        resolve(response.authResponse.accessToken);
      }
    }, {
      scope: 'email',
    });
  });
}

export interface FBUser {
  id: string;
  name: string;
  email: string;
  picture: string;
}

export async function getFBUserInfo(token: string): Promise<FBUser> {
  const fbAPI = await getFacebookAPI();
  const fields = ['email', 'name'];

  return new Promise((resolve, reject) => {
    fbAPI.api('/me', { fields, access_token: token }, (response: any) => {
      const { id, email, name } = response;
      const picture = `https://graph.facebook.com/${id}/picture?type=large`;

      resolve({
        id,
        name,
        email,
        picture,
      });
    });
  });
}
