import * as loadjs from "loadjs";

declare const fcWidget: any;

const BUNDLE_NAME = "FreshChat";

const apiToken = process.env.FRESHCHAT_TOKEN;

function loadFreshChatAPI() {
  return new Promise((resolve) => {
    loadjs(["https://wchat.freshchat.com/js/widget.js"], BUNDLE_NAME, () => {
      fcWidget.init({
        token: apiToken,
        host: "https://wchat.freshchat.com",
      });
      resolve(fcWidget);
    });
  });
}

export function getFreshChatAPI(): Promise<any> {
  if (loadjs.isDefined(BUNDLE_NAME)) {
    return new Promise((resolve) => {
      resolve(fcWidget);
    });
  } else {
    return loadFreshChatAPI();
  }
}

export async function showChatWidget() {
  const freshChat = await getFreshChatAPI();
  freshChat.show();
}

export async function hideChatWidget() {
  const freshChat = await getFreshChatAPI();
  freshChat.hide();
}
