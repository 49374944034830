import axios from "axios";

import { UserInput, ProfileInput } from "../components/home/register/UserStep";
import { StudentInput } from "../components/home/register/AddStudentStep";

const getBase64 = (file: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = (error) => reject(error);
  });
};

const getDateFromString = (dateString: string) => {
  const [day, month, year] = dateString.split("/");
  return new Date(`${month}/${day}/${year}`);
};

const prepareDateToBackend = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const dayStr = day < 10 ? "0" + day : day;
  const monthStr = month < 10 ? "0" + month : month;

  return `${year}-${monthStr}-${dayStr}`;
};

const prepareDateFromString = (date: string) => {
  const [day, month, year] = date.split("/");

  return `${year}-${month}-${day}`;
};

const prepareParamsToCheckStudent = (
  name: string,
  birthDate: string,
  identifier: string,
  organization?: string
) => {
  const params = new URLSearchParams();
  params.set("fullName", name);
  params.set("birthDt", prepareDateFromString(birthDate));
  params.set("registry", identifier);

  if (organization) {
    params.set("company", organization);
  }

  return params;
};

const convertToIsoString = (birthday: string) => {
  const date = birthday.split("/");
  const formattedDate = new Date(
    `${date[2]}-${date[1]}-${date[0]}T00:00:00.000Z`
  );
  const finalDate = formattedDate.toISOString();

  return finalDate;
};

export interface CheckStudentResponse {
  valid: boolean;
  companies?: any[];
}

export class RegisterAPI {
  verifyValidEmail = async (email: string) => {
    const params = new URLSearchParams();
    params.set("email", email);
    const url = `/v2/account/validEmail/${email}`;
    let response;

    try {
      response = await axios.get(url);
    } catch (error) {
      return error.response.data;
    }

    return response.data;
  };

  register = async (
    user: UserInput,
    student: StudentInput,
    profile: ProfileInput
  ) => {
    const studentBirthDateStr = getDateFromString(student.birthDate);
    const studentBirthDate = prepareDateToBackend(studentBirthDateStr);

    const { picture, pictureFile } = user;
    let picturePath: string;
    let pictureBase64: any;

    if (pictureFile === undefined) {
      picturePath = picture;
    } else {
      const { type: mimeType } = pictureFile;
      const base64 = await getBase64(pictureFile);
      const base64Img = base64.split("base64,").pop();

      pictureBase64 = { base64Img, mimeType };
    }

    const requestData: Record<string, any> = {
      studentName: student.name,
      cardNumber: student.cardCode,
    };

    if (student.identifier && student.identifier !== "") {
      requestData.identifier = student.identifier;
    }

    if (student.birthDate && student.birthDate !== "") {
      requestData.birthday = convertToIsoString(student.birthDate);
    }

    const response = await axios.post("/v2/account/register", {
      email: user.email,
      password: user.password !== "" ? user.password : undefined,
      name: user.name,
      shortName: user.nickname,
      student: requestData,
    });

    return response.data;
  };

  activateStudentCard = async (cardCode: string) => {
    const uri = `/v2/account/validCard/${cardCode}`;

    const response = await axios
      .get(uri)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });

    return response;
  };
}
