import { action, computed, observable } from 'mobx';

import { UserAPI } from '../api/UserAPI';

export interface Address {
  streetName?: string;
  neighborhood?: string;
  complement?: string;
}

export interface User {
  id: number;
  companyID: number;
  name: string;
  email: string;
  nickname?: string;
  image?: string;
  phone?: string;
  address: Address;
  birthDate?: Date;
}

export class UserStore {

  @observable performingLogin: boolean = false;

  constructor(private api: UserAPI) { }

  @action
  afterLogin = (response: any) => {
    const { authenticated, token } = response;

    if (authenticated) {
      localStorage.setItem('token', token);
    }

    this.performingLogin = false;
    return response;
  }

  @action
  login = async (email: string, password: string): Promise<any> => {
    this.performingLogin = true;
    const response = await this.api.login(email, password);
    
    return this.afterLogin(response);
  }

  @action
  loginFacebook = async (facebookToken: string): Promise<any> => {
    this.performingLogin = true;
    const response = await this.api.loginFacebook(facebookToken);

    return this.afterLogin(response);
  }

  @action
  loginGoogle = async (googleToken: string): Promise<any> => {
    this.performingLogin = true;
    const response = await this.api.loginGoogle(googleToken);

    return this.afterLogin(response);
  }

}
