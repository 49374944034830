import * as React from 'react';
import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { Icon } from '../icon/Icon';

import { LoginContainer } from './LoginContainer';

import './Header.scss';

interface HeaderProps {
  toggleLoginContainer: () => void;
  isLoginContainerOpen: boolean;
}

@observer
export class Header extends Component<HeaderProps> {

  refLoginContainer: React.RefObject<HTMLDivElement> = React.createRef();

  @observable isMenuOpen: boolean = false;
  @observable className: string = '';

  componentDidMount() {
    document.addEventListener('click', this.onClickDocument);
    document.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickDocument);
    document.removeEventListener('scroll', this.onScroll);
  }

  @action
  onScroll = () => {
    const { scrollTop } = document.documentElement;
    this.className = scrollTop > 0 ? 'sticky' : '';
  }

  @action
  onClickDocument = (event: any) => {
    const { refLoginContainer } = this;

    if (this.props.isLoginContainerOpen &&
      !refLoginContainer.current.contains(event.target)
    ) {
      this.closeLoginContainer();
    }
  }

  @action
  toggleLoginContainer = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    this.props.toggleLoginContainer();
  }

  @action
  closeLoginContainer = () => {
    this.props.toggleLoginContainer();
  }

  @action
  toggleMenu = () => {
    this.isMenuOpen = !this.isMenuOpen;
  }

  scrollToElement = (event: React.SyntheticEvent, element: HTMLElement) => {
    event.preventDefault();
    this.toggleMenu();

    window.scrollTo(0, element.offsetTop - 60);
  }

  @action
  scrollToVideo = (event: React.SyntheticEvent) => {
    const element = document.getElementById('video');
    this.scrollToElement(event, element);
  }

  @action
  scrollToContactForm = (event: React.SyntheticEvent) => {
    const element = document.getElementById('contact');
    this.scrollToElement(event, element);
  }

  @action
  scrollToFAQ = (event: React.SyntheticEvent) => {
    const element = document.getElementById('faq');
    this.scrollToElement(event, element);
  }

  @action
  showContactAndCloseLoginContainer = (event: React.SyntheticEvent) => {
    this.closeLoginContainer();
    this.scrollToContactForm(event);
  }

  render() {
    const loginContainerClass = this.props.isLoginContainerOpen ? 'open' : '';
    const activeClassLogin = this.props.isLoginContainerOpen ? 'active' : '';
    const menuClass = this.isMenuOpen ? 'open' : '';

    return (
      <header className={`lp-header ${this.className}`}>
        <div className="container">
          <div className="logo" />
          <div onClick={this.toggleMenu} className="menu-button">
            <Icon icon="bars" solid />
          </div>
          <nav className={menuClass}>
            {/* <a
              onClick={this.scrollToVideo}
              className="nav-item"
            >
              Cantina Web
            </a> */}
            <a
              onClick={this.scrollToContactForm}
              className="nav-item"
            >
              Contato
            </a>
            {/* <a */}
              {/* onClick={this.scrollToFAQ} */}
              {/* className="nav-item" */}
            {/* > */}
              {/* Perguntas frequentes */}
            {/* </a> */}
            <a
              onClick={this.toggleLoginContainer}
              className={`nav-item has-tip login-link ${activeClassLogin}`}
            >
              <Icon solid icon="sign-in-alt" />
              Minha conta
            </a>
          </nav>
        </div>
        <div
          ref={this.refLoginContainer}
          className={`login-container ${loginContainerClass}`}
        >
          <LoginContainer            
            onClickContact={this.showContactAndCloseLoginContainer}                        
          />
        </div>
      </header>
    );
  }

}
