import * as React from 'react';
import { Component } from 'react';

import './Card.scss';

interface CardProps {
  className: string;
  title?: string;
  headerAddon?: React.ReactNode;
}

export class Card extends Component<CardProps> {

  static defaultProps = {
    className: '',
  };

  renderTitle = () => {
    const { props } = this;
    const { title, headerAddon } = props;

    if (!title && !headerAddon) {
      return;
    }

    return (
      <div className="n-card__header">
        { title && <h2 className="n-card__title">{ title }</h2> }
        { headerAddon }
      </div>
    );
  }

  render() {
    const { props } = this;
    const { children, className } = props;

    return (
      <div className={`n-card ${className}`}>
        { this.renderTitle() }
        { children }
      </div>
    );
  }

}
