import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import './CircleLoading.scss';

interface CircleLoadingProps {

}

@observer
export class CircleLoading extends Component<CircleLoadingProps> {

  render() {
    return (
      <div className="circle-loading" />
    );
  }

}
