import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { NotificationManager } from './NotificationManager';

import './NotificationContainer.scss';

interface NotificationContainerProps {

}

@observer
export class NotificationContainer
  extends Component<NotificationContainerProps>
{

  renderNotifications = () => {
    const { notifications } = NotificationManager;

    return notifications.map((notification: any, index) => {
      return (
        <CSSTransition key={notification.id} classNames="fade" timeout={300}>
          { notification.notification }
        </CSSTransition>
      );
    });
  }

  render() {
    return (
      <TransitionGroup className="n-notification-container">
        { this.renderNotifications() }
      </TransitionGroup>
    );
  }

}
