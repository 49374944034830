import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import { Button } from '../../../button/Button';
import { Form } from '../../../form/Form';
import { Icon } from '../../../icon/Icon';

import '../../../../assets/grid-template.scss';

interface SuccessStepProps {
  closeModal: () => void;
}

@observer
export class SuccessStep extends Component<SuccessStepProps> {

  render() {
    return (
      <Form>
        <Form.Field className="message col-12">
          <Icon solid icon="check-circle" />
          <span>Sua senha foi alterada com sucesso.</span>
        </Form.Field>
        <Form.Field className="col-12 buttons">
          <Button.Group>
            <Button
              onClick={this.props.closeModal}
              className="primary"
            >
              Ok
            </Button>
          </Button.Group>
        </Form.Field>
      </Form>
    );
  }

}
