import axios from 'axios';

export class ForgetPasswordAPI {
  requestEmail = async (email: string) => {
    const uri = "/v2/account/RecoveryPassword";
    const response = await axios.get(uri, { params: { email } });

    return response.data;
  };

  verifyToken = async (token: string, email: string) => {
    const uri = `/v2/account/validateToken/token/${token}/email/${email}`;
    const response = await axios.get(uri);

    return response.data;
  };

  changePassword = async (
    token: string,
    email: string,
    newPassoword: string,
    confirmNewPassword: string
  ) => {
    const uri = `/v2/account/recovery/changePassword`;
    const response = await axios.post(uri, {
      token,
      email,
      newPassoword,
      confirmNewPassword,
    });

    return response.data;
  };
}

export const forgetPasswordAPI = new ForgetPasswordAPI();
