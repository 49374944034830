import * as React from 'react';
import { Component } from 'react';
import { Route, RouteProps } from 'react-router';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { Header } from '../components/home/Header';
import { Footer } from '../components/home/footer/Footer';

interface HomeRouteProps extends RouteProps {
}

@observer
export class HomeRoute extends Component<HomeRouteProps> {

  @observable loginIsOpen: boolean = false;

  @action
  toggleLoginContainer = () => {
    this.loginIsOpen = !this.loginIsOpen;
  }

  renderInnerComponent = (loginIsOpen: boolean) => (matchProps: any) => {
    const { props } = this;
    const { component: Component } = props;
    return (
      <div>
        <Header
          toggleLoginContainer={this.toggleLoginContainer}
          isLoginContainerOpen={loginIsOpen}
        />
        <div className="page-content">
          <Component {...matchProps} />
        </div>
        <Footer />
      </div>
    );
  }

  render() {
    const { props } = this;
    const { component: _, ...otherProps } = props;

    const renderComponent = this.renderInnerComponent(this.loginIsOpen);

    return (
      <Route
        {...otherProps}
        render={renderComponent}
      />
    );
  }

}
