import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import { Field } from './Field';
import { Label } from './Label';
import { Message } from './Message';

import './Form.scss';

interface FormProps {
  id?: string;
  className: string;
  onSubmit: (event: React.FormEvent) => void;
}

@observer
export class Form extends Component<FormProps> {

  static Field = Field;
  static Label = Label;
  static Message = Message;

  static defaultProps = {
    className: '',
    onSubmit: () => {},
  };

  render() {
    return (
      <div className={`n-form ${this.props.className}`}>
        <form
          id={this.props.id}
          onSubmit={this.props.onSubmit}
        >
          { this.props.children }
        </form>
      </div>
    );
  }

}
