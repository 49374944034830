import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';

import {
  NotificationContainer,
} from './components/notification/NotificationContainer';

import './plugins/axios';

import { App } from './App';
import { stores } from './stores';

ReactDOM.hydrate((
  <>
    <NotificationContainer />
    <Provider {...stores}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </>
), document.getElementById("app"));
