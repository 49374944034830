import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import './Field.scss';

interface FieldProps {
  className: string;
}

@observer
export class Field extends Component<FieldProps> {

  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <div className={`n-field ${this.props.className}`}>
        { this.props.children }
      </div>
    );
  }

}
