import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import './Message.scss';

export enum MessageType {
  Hint = 'hint',
  Error = 'error',
}

interface MessageProps {
  type: MessageType;
}

@observer
export class Message extends Component<MessageProps> {

  static defaultProps = {
    type: MessageType.Hint,
  };

  render() {
    return (
      <div className={`n-message ${this.props.type}`}>
        { this.props.children }
      </div>
    );
  }

}
