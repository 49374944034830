import * as React from 'react';
import { Component } from 'react';

import './Icon.scss';

interface IconProps {
  className: string;
  icon: string;
  brand: boolean;
  solid: boolean;
  rotate: boolean;
  title?: string;
  onClick: (event: React.MouseEvent) => void;
}

export class Icon extends Component<IconProps> {

  static defaultProps = {
    className: '',
    brand: false,
    solid: false,
    rotate: false,
    onClick: () => {},
  };

  render() {
    const { props } = this;
    const { className, icon, brand, solid, rotate, title, onClick } = props;

    const brandClass = brand ? 'brand' : '';
    const solidClass = solid ? 'solid' : '';
    const rotateClass = rotate ? 'rotate' : '';

    const classes = [
      'n-icon', className, icon, brandClass, solidClass, rotateClass,
    ];

    return (
      <span
        title={title}
        className={classes.join(' ')}
        onClick={onClick}
      />
    );
  }

}
