import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import { StepGroup } from './StepGroup';

import './Step.scss';

interface StepProps {
  title?: string;
  active: boolean;
  completed: boolean;
  onClick: () => void;
  icon: string;
}

@observer
export class Step extends Component<StepProps> {

  static Group = StepGroup;

  static defaultProps = {
    active: false,
    completed: false,
    onClick: () => {},
  };

  render() {
    const { props } = this;
    const { active, onClick, completed, title, icon } = props;

    const completedClassName = completed ? 'completed' : '';
    const activeClassName = active ? 'active' : '';

    return (
      <div className={`step ${completedClassName} ${activeClassName}`}>
        <div className="title">{ title }</div>
        <span className="content" onClick={onClick}>
          <img className="icon-svg" src={icon} alt=""/>
        </span>
      </div>
    );
  }
}
