import * as React from "react";
import { Component } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";

import { Icon } from "../../icon/Icon";

import { showChatWidget, hideChatWidget } from "../../../plugins/freshChat";

import "../../../assets/grid-template.scss";
import "./Footer.scss";

interface FooterProps {}

const EMAIL = "contatos@nebulasistemas.com.br";
const WHATS_APP = "(31) 3050-1040";
const WHATS_APP_LINK = "553130501040";
const PHONE = "(31) 3059-1040";
const PHONE_LINK = "3130591040";

export const LINKEDIN_LINK = "https://www.linkedin.com/nebulasistemas/";
export const INSTAGRAM_LINK = "https://www.instagram.com/cantinaweb/";
export const FACEBOOK_LINK = "https://www.facebook.com/nebulasistemas/";

@observer
export class Footer extends Component<FooterProps> {
  componentDidMount() {
    // showChatWidget();
  }

  componentWillUnmount() {
    hideChatWidget();
  }

  scrollToElement = (event: React.SyntheticEvent, element: HTMLElement) => {
    event.preventDefault();
    window.scrollTo(0, element.offsetTop - 60);
  };

  @action
  scrollToVideo = (event: React.SyntheticEvent) => {
    const element = document.getElementById("video");
    this.scrollToElement(event, element);
  };

  @action
  scrollToContactForm = (event: React.SyntheticEvent) => {
    const element = document.getElementById("contact");
    this.scrollToElement(event, element);
  };

  @action
  scrollToFAQ = (event: React.SyntheticEvent) => {
    const element = document.getElementById("faq");
    this.scrollToElement(event, element);
  };

  render() {
    return (
      <div className="lp-footer">
        <div className="container">
          <div className="footer-item col-12 col-sm-6 col-md-4 col-lg-4">
            <h3>Cantina Web</h3>
            <a href="#" onClick={this.scrollToVideo}>
              Cantina Web
            </a>
            <a href="#" onClick={this.scrollToContactForm}>
              Contato
            </a>
            {/* <a href="#" onClick={this.scrollToFAQ}>Perguntas frequentes</a> */}
          </div>
          <div className="footer-item col-12 col-sm-6 col-md-3 col-lg-4">
            <h3>LINKS</h3>
            <a
              href="http://nebulasistemas.com.br"
              target="_blank"
              rel="noopener"
            >
              Nébula Sistemas
            </a>
            <a
              href="http://portal.nebulasistemas.com.br/revenda/index"
              target="_blank"
              rel="noopener"
            >
              Seja uma revenda
            </a>
          </div>
          <div className="footer-item contact-info col-12 col-md-5 col-lg-4">
            <h3>FALE CONOSCO</h3>
            {/* <a */}
            {/* className="phone" */}
            {/* href={`tel:${PHONE_LINK}`} */}
            {/* > */}
            {/* <Icon icon="phone" solid /> { PHONE } */}
            {/* </a> */}
            <a
              className="whatsapp"
              href={`https://api.whatsapp.com/send?phone=${WHATS_APP_LINK}`}
              target="_blank"
              rel="noopener"
            >
              <Icon icon="whatsapp" brand /> {WHATS_APP}
            </a>
            <a className="email" href={`mailto:${EMAIL}`}>
              <Icon icon="envelope" solid /> {EMAIL}
            </a>
            <div className="social-media">
              <a
                href={INSTAGRAM_LINK}
                target="_blank"
                rel="noopener"
                aria-label="Instagram"
              >
                <Icon brand icon="instagram" />
              </a>
              <a
                href={FACEBOOK_LINK}
                target="_blank"
                rel="noopener"
                aria-label="Facebook"
              >
                <Icon brand icon="facebook-f" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
