import * as React from 'react';
import { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';

import { Button } from '../../../button/Button';
import { Form } from '../../../form/Form';
import { Input } from '../../../input/Input';
import { LoadingOverlay } from '../../../loading/LoadingOverlay';
import { MessageType } from '../../../form/Message';
import { NotificationManager } from '../../../notification/NotificationManager';

import { forgetPasswordAPI } from '../../../../api/ForgetPasswordAPI';

import '../../../../assets/grid-template.scss';

interface ConfirmTokenStepProps {
  email: string;
  token: string;
  onChangeToken: (token: string) => void;
  goToStepGetEmail: () => void;
  onTokenValid: () => void;
}

@observer
export class ConfirmTokenStep extends Component<ConfirmTokenStepProps> {

  @observable isSendingToken: boolean = false;

  @observable errors: string[] = [];

  @computed
  get tokenStatus() {
    return this.isTokenValid ? '' : 'error';
  }

  @computed
  get isTokenValid() {
    return this.errors.length === 0;
  }

  @action
  onChangeToken = (_: string, token: string) => {
    this.props.onChangeToken(token);
    this.validateToken(token);
  }

  @action
  submitConfirmToken = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    const { props } = this;
    const { token, email } = props;

    this.validateToken(token);

    if (!this.isTokenValid) {
      return;
    }

    try {
      this.isSendingToken = true;
      const response = await forgetPasswordAPI.verifyToken(token, email);
      this.afterSendToken(response);
    } catch (error) {
      this.onSendTokenError(error);
    }
  }

  @action
  afterSendToken = (_: any) => {
    this.isSendingToken = false;
    this.props.onTokenValid();
  }

  @action
  onSendTokenError = (error: AxiosError) => {
    this.isSendingToken = false;
    const message = `
      O código informado está expirado. Socilite outro código e tente novamente.
    `;

    NotificationManager.error({
      title: 'Código expirado',
      message,
    });
  }

  @action
  validateToken = (token: string) => {
    const errors: string[] = [];

    if (token === '') {
      errors.push('Campo obrigatório');
    }

    this.errors = errors;
  }

  renderErrorMessage = () => {
    const error = this.errors
      && this.errors.length > 0
      && this.errors[0];

    if (error) {
      return <Form.Message type={MessageType.Error}>{ error }</Form.Message>;
    }

    return null;
  }

  render() {
    return (
      <Form>
        <Form.Field className="message col-12">
          Insira abaixo o código enviado para seu e-mail.
        </Form.Field>
        <Form.Field className={`col-12 ${this.tokenStatus}`}>
          <Form.Label
            required={true}
            isValid={this.isTokenValid}
          >
            Código
          </Form.Label>
          <Input
            value={this.props.token}
            name="token"
            onChange={this.onChangeToken}
            autoFocus={true}
            autoCapitalize="none"
            autoCorrect="none"
            autoComplete="off"
          />
          { this.renderErrorMessage() }
        </Form.Field>
        <Form.Field className="col-12 buttons">
          <Button
            onClick={this.props.goToStepGetEmail}
          >
            Voltar
          </Button>
          <LoadingOverlay isLoading={this.isSendingToken}>
            <Button
              submit
              onClick={this.submitConfirmToken}
              className="primary"
              disabled={!this.isTokenValid}
            >
              Continuar
            </Button>
          </LoadingOverlay>
        </Form.Field>
      </Form>
    );
  }

}
