import * as React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import { action, observable } from "mobx";
import { observer, inject } from "mobx-react";

import { Button } from "../button/Button";
import { LoginForm } from "./login/LoginForm";
import { ModalHelper } from "../modal/ModalHelper";
import { showChatWidget, hideChatWidget } from "../../plugins/freshChat";

import { RegisterForm } from "./register/RegisterForm";
import { RegisterStore } from "../../stores/RegisterStore";
import { ForgetPassword } from "./login/ForgetPassword";

import "./LoginContainer.scss";
import { UserStore } from "../../stores/UserStore";

interface LoginContainerProps {
  register?: RegisterStore;
  user?: UserStore;
  onClickContact: (event: React.SyntheticEvent) => void;
}

const nebulaCardAppURL = process.env.NEBULA_CARD_APP_URL;

@inject("register", "user")
@observer
export class LoginContainer extends Component<LoginContainerProps> {
  @observable forgetPasswordModalOpen: boolean = false;

  @action
  openRegisterModal = () => {
    const { props } = this;
    const { register: registerStore } = props;

    registerStore.setIsFormOpen(true);

    document.body.classList.add("modal-open");
    hideChatWidget();
  };

  @action
  closeRegisterModal = () => {
    const { props } = this;
    const { register: registerStore } = props;

    registerStore.setIsFormOpen(false);
    registerStore.resetForm();
    document.body.classList.remove("modal-open");
    // showChatWidget();
  };

  @action
  onFinishRegister = async (event: React.MouseEvent) => {
    const { register: registerStore, user: userStore } = this.props;
    console.log(userStore.performingLogin);

    const response = await userStore.login(
      registerStore.user.email,
      registerStore.user.password
    );
    console.log(userStore.performingLogin);

    const { token } = response.data;
    window.location.href = `${nebulaCardAppURL}?token=${token}`;

    this.closeRegisterModal();
  };

  @action
  openRecoverPasswordModal = (email: string) => {
    document.body.classList.add("modal-open");
    this.forgetPasswordModalOpen = true;
  };

  @action
  closeRecoverPasswordModal = () => {
    document.body.classList.remove("modal-open");
    this.forgetPasswordModalOpen = false;
  };

  render() {
    const { props } = this;
    const { register: registerStore } = props;

    return (
      <div className="lp-login-container">
        <ModalHelper
          className="basic-modal"
          isOpen={registerStore.isFormOpen}
          onCloseModal={this.closeRegisterModal}
        >
          <RegisterForm
            onCancel={this.closeRegisterModal}
            onFinishRegister={this.onFinishRegister}
          />
        </ModalHelper>
        <ModalHelper
          className="basic-modal forget-password-modal"
          isOpen={this.forgetPasswordModalOpen}
          onCloseModal={this.closeRecoverPasswordModal}
        >
          <ForgetPassword onCloseModal={this.closeRecoverPasswordModal} />
        </ModalHelper>
        <div className="container">
          {/* <div className="register col-12 col-md-7">
            <h3>Ainda não possui o Cantina Web em sua cantina?</h3>
            <p>
              Entre em contato com um de nossos consultores e saiba como
              o <b>Cantina Web</b> pode te ajudar a aumentar suas vendas,
              diminuir as filas e acelerar o atendimento de sua cantina escolar.
            </p>
            <div className="buttons">
              <Button
                className="secondary"
                onClick={this.props.onClickContact}
              >
                Converse conosco
              </Button>
            </div>
          </div> */}
          <div className="login col-12 col-md-6 pos-relative">
            <div className="logo-container"></div>
            <LoginForm
              openRecoverPasswordModal={this.openRecoverPasswordModal}
            />
            <div
              className="link link-register"
              onClick={this.openRegisterModal}
            >
              Não tenho cadastro
            </div>
            <div className="link link-register">
              Versão {process.env.VERSION_APP}
            </div>
            <div className="link site">
              <a target="_blank" href="https://nebulasistemas.com.br/">
                nebulasistemas.com.br
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
