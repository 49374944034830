import * as React from 'react';
import { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { Card } from '../../card/Card';

import { GetEmailStep } from './forget-password/GetEmailStep';
import { ConfirmTokenStep } from './forget-password/ConfirmTokenStep';
import { ChangePasswordStep } from './forget-password/ChangePasswordStep';
import { SuccessStep } from './forget-password/SuccessStep';

import '../../../assets/grid-template.scss';
import './ForgetPassword.scss';

enum ForgetPasswordStep {
  GetEmail,
  ConfirmToken,
  ChangePassword,
  Success,
}

interface ForgetPasswordProps {
  onCloseModal: () => void;
}

@observer
export class ForgetPassword extends Component<ForgetPasswordProps> {

  @observable email: string = '';
  @observable token: string = '';

  @observable step: ForgetPasswordStep = ForgetPasswordStep.GetEmail;

  @action
  goToStepGetEmail = () => {
    this.step = ForgetPasswordStep.GetEmail;
  }

  @action
  goToStepConfirmToken = () => {
    this.step = ForgetPasswordStep.ConfirmToken;
  }

  @action
  goToStepChangePassword = () => {
    this.step = ForgetPasswordStep.ChangePassword;
  }

  @action
  goToStepSuccess = () => {
    this.step = ForgetPasswordStep.Success;
  }

  @action
  closeModal = () => {
    this.props.onCloseModal();
  }

  @action
  onEmailSent = () => {
    this.step = ForgetPasswordStep.ConfirmToken;
  }

  @action
  onTokenValid = () => {
    this.step = ForgetPasswordStep.ChangePassword;
  }

  @action
  onPasswordChanged = () => {
    this.step = ForgetPasswordStep.Success;
  }

  @action
  onChangeEmail = (email: string) => {
    this.email = email;
  }

  @action
  onChangeToken = (token: string) => {
    this.token = token;
  }

  renderGetEmailStep = () => {
    return (
      <GetEmailStep
        email={this.email}
        onChangeEmail={this.onChangeEmail}
        onCloseModal={this.closeModal}
        onEmailSent={this.onEmailSent}
      />
    );
  }

  renderConfirmTokenStep = () => {
    return (
      <ConfirmTokenStep
        email={this.email}
        token={this.token}
        onChangeToken={this.onChangeToken}
        goToStepGetEmail={this.goToStepGetEmail}
        onTokenValid={this.onTokenValid}
      />
    );
  }

  renderChangePasswordStep = () => {
    return (
      <ChangePasswordStep
        email={this.email}
        token={this.token}
        goToStepConfirmToken={this.goToStepConfirmToken}
        onPasswordChanged={this.onPasswordChanged}
      />
    );
  }

  renderSuccessStep = () => {
    return (
      <SuccessStep
        closeModal={this.closeModal}
      />
    );
  }

  renderStep = () => {
    switch (this.step) {
      case ForgetPasswordStep.GetEmail:
        return this.renderGetEmailStep();
      case ForgetPasswordStep.ConfirmToken:
        return this.renderConfirmTokenStep();
      case ForgetPasswordStep.ChangePassword:
        return this.renderChangePasswordStep();
      case ForgetPasswordStep.Success:
        return this.renderSuccessStep();
    }
  }

  render() {
    return (
      <Card className="forget-password" title="Esqueci minha senha">
        { this.renderStep() }
      </Card>
    );
  }

}
