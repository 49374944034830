import * as React from "react";
import { Component } from "react";
import { withRouter } from "react-router";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { History } from "history";

import { Button } from "../../button/Button";
import { Divisor } from "../../divisor/Divisor";
import { Form } from "../../form/Form";
import { Icon } from "../../icon/Icon";
import { Input } from "../../input/Input";
import { PasswordInput } from "../../password/PasswordInput";

import { NotificationManager } from "../../notification/NotificationManager";
import { UserStore } from "../../../stores/UserStore";
import { RegisterStore } from "../../../stores/RegisterStore";
import {
  getGoogleUserAccessToken,
  getGoogleUserInfo,
  CHROME_POPUP_TUTORIAL,
  FIREFOX_POPUP_TUTORIAL,
} from "../../../plugins/googleApi";
import {
  getFBUserAccessToken,
  getFBUserInfo,
} from "../../../plugins/facebookApi";

const nebulaCardAppURL = process.env.NEBULA_CARD_APP_URL;
//const nebulaCardAppURL = 'http://app-dev.cantinaweb.com.br';

import "./LoginForm.scss";

interface LoginData {
  email: string;
  password: string;
}

interface LoginFormProps {
  user?: UserStore;
  register?: RegisterStore;
  history?: History;
  openRecoverPasswordModal: (email: string) => void;
}

@(withRouter as any)
@inject("user", "register")
@observer
export class LoginForm extends Component<LoginFormProps> {
  loginErrorNotifications: number[] = [];

  @observable isLoading: boolean = false;

  @observable login: LoginData = {
    email: "",
    password: "",
  };

  @action
  onChangeLoginField = (name: keyof LoginData, value: string) => {
    this.login[name] = value;
  };

  @action
  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  loginFacebook = async () => {
    const token = await getFBUserAccessToken();
    const { props } = this;
    const { user: userStore } = props;

    const response = await userStore.loginFacebook(token);

    if (response.authenticated === false) {
      if (response.error === "not_registered") {
        this.onFacebookNotRegistered(token);
      }

      return;
    }

    this.redirectToAPP(response);
  };

  @action
  onFacebookNotRegistered = async (token: string) => {
    const { id, name, email, picture } = await getFBUserInfo(token);

    this.openRegisterModalWithFacebookData(id, name, email, picture);
  };

  @action
  openRegisterModalWithFacebookData = (
    id: string,
    name: string,
    email: string,
    picture: string
  ) => {
    const { props } = this;
    const { register: registerStore } = props;

    registerStore.setIsFormOpen(true);
    registerStore.goToNextStep();
    registerStore.onChangeUserInput("facebookID", id);
    registerStore.onChangeUserInput("name", name);
    registerStore.onChangeUserInput("email", email);
    registerStore.onChangeUserInput("picture", picture);
  };

  @action
  loginGoogle = async () => {
    try {
      const { props } = this;
      const { user: userStore, history } = props;

      const token = await getGoogleUserAccessToken();
      const response = await userStore.loginGoogle(token);

      if (response.authenticated === false) {
        if (response.error === "not_registered") {
          this.onGoogleNotRegistered(token);
        }

        return;
      }

      this.redirectToAPP(response);
    } catch (error) {
      this.handleGoogleError(error);
    }
  };

  @action
  onGoogleNotRegistered = (token: string) => {
    const { id, name, email, picture } = getGoogleUserInfo(token);

    this.openRegisterModalWithGoogleData(id, name, email, picture);
  };

  @action
  openRegisterModalWithGoogleData = (
    id: string,
    name: string,
    email: string,
    picture: string
  ) => {
    const { props } = this;
    const { register: registerStore } = props;

    registerStore.setIsFormOpen(true);
    registerStore.goToNextStep();
    registerStore.onChangeUserInput("googleID", id);
    registerStore.onChangeUserInput("name", name);
    registerStore.onChangeUserInput("email", email);
    registerStore.onChangeUserInput("picture", picture);
  };

  @action
  handleGoogleError = (error: string) => {
    switch (error) {
      case "popup_blocked_by_browser":
        NotificationManager.warning({
          title: "Pop-ups bloqueados",
          message: (
            <span>
              Não foi possível autenticar com o Google pois os pop-ups estão
              bloqueados no seu navegador. Para autorizar os pop-ups siga as
              instruções nas páginas do seu navegador:{" "}
              <a href={CHROME_POPUP_TUTORIAL} target="_blank">
                Google Chrome
              </a>{" "}
              ou{" "}
              <a href={FIREFOX_POPUP_TUTORIAL} target="_blank">
                Mozilla Firefox
              </a>
              .
            </span>
          ),
        });
        break;
      default:
        break;
    }
  };

  @action
  onClickLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    this.closeErrorNotifications();

    const { props, login } = this;
    const { history, user: userStore } = props;

    const { email, password } = login;

    if (email === "" || password === "") {
      NotificationManager.warning({
        title: "Informe e-mail e senha",
        message: `E-mail e senha devem ser informados para prosseguir.`,
      });
      return;
    }

    try {
      this.setLoading(true);
      const response = await userStore.login(email, password);

      if (response.authenticated === false) {
        const { id } = NotificationManager.error({
          title: "Usuário não encontrado",
          message: `
            Não foi encontrado um usuário com o e-mail e senha informados.
          `,
        });
        this.loginErrorNotifications.push(id);

        return;
      } else {
        this.redirectToAPP(response);
      }
    } catch (e) {
      const { id } = NotificationManager.error({
        title: "Usuário não encontrado",
        message: `
          Não foi encontrado um usuário com o e-mail e senha informados.
        `,
      });
      this.loginErrorNotifications.push(id);
    } finally {
      this.setLoading(false);
    }
  };

  @action
  closeErrorNotifications = () => {
    this.loginErrorNotifications.forEach((notificationID) => {
      NotificationManager.close(notificationID);
    });
  };

  @action
  openRecoverPasswordModal = () => {
    this.props.openRecoverPasswordModal(this.login.email);
  };

  @action
  redirectToAPP = (response: any) => {
    const { token } = response.data;

    window.location.href = `${nebulaCardAppURL}?token=${token}`;
  };

  render() {
    return (
      <div className="login-form">
        {/* <Button.Group> */}
        {/* <Button onClick={this.loginFacebook} className="facebook"> */}
        {/* <Icon brand icon="facebook" /> */}
        {/* </Button> */}
        {/* <Button onClick={this.loginGoogle} className="danger"> */}
        {/* <Icon brand icon="google" /> */}
        {/* </Button> */}
        {/* </Button.Group> */}
        {/* <Divisor>ou</Divisor> */}
        <Form>
          <Form.Field className="col-12 spacefix">
            <Form.Label htmlFor="login-email">E-mail</Form.Label>
            <Input
              id="login-email"
              value={this.login.email}
              name="email"
              onChange={this.onChangeLoginField}
              inputMode="email"
              autoCapitalize="none"
              autoCorrect="none"
              autoComplete="email"
            />
          </Form.Field>
          <Form.Field className="col-12">
            <Form.Label htmlFor="login-password">Senha</Form.Label>
            <PasswordInput
              id="login-password"
              type="password"
              value={this.login.password}
              name="password"
              onChange={this.onChangeLoginField}
            />
          </Form.Field>
          <Form.Field className="col-12 submit">
            <Button
              submit
              disabled={this.isLoading}
              onClick={this.onClickLogin}
              className="success"
            >
              Entrar
            </Button>
          </Form.Field>
          <Form.Field className="col-12 submit">
            <div
              className="link forgot-password"
              onClick={this.openRecoverPasswordModal}
            >
              Esqueci minha senha
            </div>
          </Form.Field>
        </Form>
      </div>
    );
  }
}
