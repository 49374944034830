import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader';

import { Home } from './pages/Home';
import { HomeRoute } from './pages/HomeRoute';

import './assets/style/base';

@(withRouter as any)
@observer
@hot(module)
export class App extends Component {

  render() {
    return (
      <Switch>
        <HomeRoute exact path="/" component={Home} />
      </Switch>
    );
  }
}

