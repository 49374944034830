import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import { Input, InputProps } from '../input/Input';

const birthDateMaskProps = {
  guide: true,
  keepCharPositions: true,
  mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
};

interface BirthDateInputProps extends InputProps {

}

@observer
export class BirthDateInput extends Component<BirthDateInputProps> {

  static defaultProps = {
    ...Input.defaultProps,
    maskProps: birthDateMaskProps,
    inputMode: "numeric",
  };

  render() {
    return <Input {...this.props} />;
  }

}
