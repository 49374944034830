import { RegisterAPI } from '../api/RegisterAPI';
import { UserAPI } from '../api/UserAPI';

import { ApplicationStore } from './ApplicationStore';
import { RegisterStore } from './RegisterStore';
import { UserStore } from './UserStore';

const registerAPI = new RegisterAPI();
const userAPI = new UserAPI();

const application = new ApplicationStore();
const register = new RegisterStore(registerAPI);
const user = new UserStore(userAPI);

export const stores = {
  application,
  register,
  user,
};
