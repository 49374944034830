function getDateFromString(dateString: string) {
  const [day, month, year] = dateString.split('/');
  return new Date(`${month}/${day}/${year}`);
}

export function isValidDate(
  date: string, minDate: string = '01/01/0000', maxDate: string = '31/12/9999',
) {
  const dateObj = getDateFromString(date);

  const minDateTime = getDateFromString(minDate).getTime();
  const maxDateTime = getDateFromString(maxDate).getTime();

  return dateObj instanceof Date
    && !isNaN(dateObj.getDate())
    && dateObj.getTime() > minDateTime
    && dateObj.getTime() < maxDateTime;
}
