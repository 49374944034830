import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import { VideoContainer } from '../components/home/VideoContainer';
import { Roles } from '../components/home/Roles';
import { Contact } from '../components/home/Contact';
import { BenefitsList } from '../components/home/benefits/BenefitsList';
import { FAQ } from '../components/home/FAQ';

import './Home.scss';
import { LoginContainer } from '../components/home/LoginContainer';
import { action } from 'mobx';

@observer
export class Home extends Component {
  
  @action
  showContactAndCloseLoginContainer = (event: React.SyntheticEvent) => {    
    this.scrollToContactForm(event);
  }

  @action
  scrollToContactForm = (event: React.SyntheticEvent) => {
    const element = document.getElementById('contact');
    this.scrollToElement(event, element);
  }

  scrollToElement = (event: React.SyntheticEvent, element: HTMLElement) => {
    event.preventDefault();    

    window.scrollTo(0, element.offsetTop - 60);
  }
  
  render() {
    return (
      <div className="home-page">

        {/* <LoginContainer /> */}
        {/* <VideoContainer /> */} 
        {/* <Roles /> */}
        <div className="wave"></div>
        <LoginContainer            
            onClickContact={this.showContactAndCloseLoginContainer}            
          />
        {/* <Contact /> */}
        {/* <BenefitsList /> */}
        {/* <FAQ /> */}
      </div>
    );
  }

}
