import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import { Icon } from '../icon/Icon';

import './Notification.scss';

interface NotificationProps {
  id: number;
  icon: string;
  title: React.ReactNode;
  message: React.ReactNode;
  timeOut: number;
  className: string;
  requestClose: (notification: any) => void;
}

@observer
export class Notification extends Component<NotificationProps> {

  static defaultProps = {
    className: '',
    requestClose: () => {},
  };

  componentDidMount() {
    const { props } = this;
    const { id, requestClose, timeOut } = props;

    if (timeOut > 0) {
      setTimeout(() => {
        requestClose(id);
      }, timeOut);
    }
  }

  requestClose = () => {
    this.props.requestClose(this.props.id);
  }

  render() {
    return (
      <div className={`n-notification ${this.props.className}`}>
        <div className="icon">
          <Icon solid icon={this.props.icon} />
        </div>
        <div className="content">
          <h3 className="title">{ this.props.title }</h3>
          <p className="message">{ this.props.message }</p>
        </div>
        <div className="close">
          <Icon solid icon="times" onClick={this.requestClose} />
        </div>
      </div>
    );
  }

}
