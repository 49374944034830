import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';

import './Label.scss';
import { Icon } from '../icon/Icon';

interface LabelProps {
  required: boolean;
  isValid: boolean;
  htmlFor?: string;
}

@observer
export class Label extends Component<LabelProps> {

  static defaultProps = {
    required: false,
    isValid: true,
  };

  renderRequiredMark = () => {
    if (!this.props.required) {
      return;
    }

    return (
      <Icon solid icon="asterisk" />
    );
  }

  renderInvalidSymbol = () => {
    if (this.props.isValid) {
      return;
    }

    return (
      <Icon solid icon="exclamation-triangle" />
    );
  }

  render() {
    const { props } = this;
    const { htmlFor } = props;

    return (
      <label className="n-label" htmlFor={htmlFor}>
        <span className="label">
          { this.props.children }
          { this.renderRequiredMark() }
        </span>
        { this.renderInvalidSymbol() }
      </label>
    );
  }

}
