import * as React from 'react';
import { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { Input, InputProps } from '../input/Input';
import { Icon } from '../icon/Icon';

export interface PasswordInputProps extends InputProps {

}

@observer
export class PasswordInput extends Component<PasswordInputProps> {

  static defaultProps = {
    type: 'password',
    readOnly: false,
    disabled: false,
    className: '',
  };

  @observable passwordVisibility: boolean = false;

  @action
  togglePasswordVisibility = () => {
    this.passwordVisibility = !this.passwordVisibility;
  }

  render() {
    const { children: _, type: _type, className, ...props } = this.props;

    const newProps = {
      ...props,
      className: `${className} icon`,
    };

    const { type, icon } = this.passwordVisibility
      ? { type: 'text', icon: 'eye' }
      : { type: 'password', icon: 'eye-slash' };

    return (
      <Input
        type={type}
        {...newProps}
      >
        <Icon solid icon={icon} onClick={this.togglePasswordVisibility} />
      </Input>
    );
  }

}
